// -GLOBAL RULES

html {
	font-size: 62.5%;
	scroll-behavior: smooth;
}

body {
	background-color: $page-background;
	color: $text-color;
	font-family: $ff;
	font-family: 'Open Sans', sans-serif;
	overflow-x: hidden;
}

a {
	text-decoration: none;
	color: $text-color;
}

.span-second {
	color: $secondary-color;
}

a:link {
	text-decoration: none;
	color: $text-color;
}

a:visited {
	text-decoration: none;
	color: $text-color;
}

a:hover {
	text-decoration: none;
	color: $text-color;
}

a:active {
	text-decoration: none;
	color: $text-color;
}
