// -MIN-WIDTH
// _4K+
@media (min-width: 3840px) {
	html {
		font-size: 70%;
	}

	.project-card {
		h2 {
			letter-spacing: 5rem;
		}
	}

	.albums {
		grid-template-columns: repeat(auto-fit, minmax(min(650px, 100%), 1fr));
	}
}

// _1024px+
@media (min-width: 1024px) {
	// -FASHIOM PAGE
	.album {
		h2::before {
			background: $secondary-color;
			background-clip: text;
			-moz-background-clip: text;
			-webkit-background-clip: text;

			-webkit-text-fill-color: transparent;
			white-space: nowrap;
			content: attr(data-text);

			position: absolute;

			left: 0;
			height: 0;
			transition: 1s ease-out;
			overflow: hidden;
		}
	}
}

@media (max-width: 1730px) {
	.port-projects {
		grid-template-columns: 1fr;
	}
}

// _TABLETS
@media (min-width: 1024px) {
	.cards-wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 2fr;
	}

	.events {
		grid-column: 1 / 3;
	}
}

// -MAX-WIDTH
// _1080p
@media (max-width: 1920px) {
	html {
		font-size: 50%;
	}
}

// _720p
@media (max-width: 1280px) {
	html {
		font-size: 50%;
	}

	// -PROJECTS SECTION (PHOTOGRAPHY & VIDEOGRAPHY)

	.project-card {
		min-height: 65vh;
	}

	// _CARD HEADER
	.card-header {
		h4 {
			letter-spacing: 1.2rem;
		}
	}
}
