.projects-header {
	min-height: 20vh;

	display: grid;
	place-content: center;

	h1 {
		font-size: $page-headers-h1;
		font-size: 8rem;
		letter-spacing: 2.8rem;
		font-weight: 800;

		// _TRANSFORM

		// opacity: 0;
		// transform: translateX(-20%);
	}
}
