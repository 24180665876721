.clients-wrapper {
	margin: 0 auto;
	padding-bottom: 4rem;

	width: 90vw;
	max-width: 220rem;
	display: grid;
	place-items: center;

	grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));

	:nth-child(4) {
		width: 75%;
	}

	img {
		object-fit: contain;
		height: 100%;
		width: 80%;
	}
}

.clients-section {
	// color: #d1c295;
	width: 100%;
	margin: 0 auto;
	margin-bottom: 2rem;
	text-align: center;

	h1 {
		padding-top: 2rem;
		letter-spacing: 0.4rem;
	}
}

@media (max-width: 700px) {
	.clients-wrapper {
		img {
			width: 70%;
		}
		:nth-child(6) {
			width: 75%;
		}
	}
}
