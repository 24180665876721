nav {
	min-height: 10vh;
	width: 80%;
	margin: 0 auto;
	z-index: 10000;

	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;

	// _a
	a {
		color: inherit;
		text-decoration: none;
		transition: all 0.5s ease-in-out;

		&:hover {
			color: lighten($text-color, 50%);
		}
	}

	p {
		cursor: pointer;
		font-weight: $fw-500;
		font-size: $nav-links-fs;
		position: relative;
		padding: 0 1rem;
		// transition: 0.5s ease-in-out;

		&:hover {
			color: $secondary-color;
		}
	}

	// _ul
	ul {
		display: flex;
		justify-content: space-evenly;
		letter-spacing: 0.75px;
	}

	// _li
	li {
		list-style: none;

		// _Navlinks hover effect
		a {
			font-weight: $fw-500;
			font-size: $nav-links-fs;
			position: relative;
			padding: 0 1rem;

			&::after {
				content: '';
				position: absolute;
				left: 0;
				bottom: -1rem;
				background-color: $secondary-color;
				height: 2px;
				width: 100%;
				transition: transform 0.75s;

				transform: scaleX(0);
				transform-origin: right;
			}

			&:hover::after {
				transform: scaleX(1);
				transform-origin: left;
			}
		}
	}
}

nav > * {
	flex: 1;
}

// _LOGO
.logo {
	position: relative;

	a {
		font-size: $logo-fs;
		font-weight: $fw-800;

		&:hover {
			color: $secondary-color;
		}
	}
}

.logo2 {
	display: block;
	font-size: 2.2rem;
	font-weight: $fw-700;

	position: absolute;
	left: 0;
	transform: translate(-25%, -20%);
}

.fixed {
	left: 10%;
	right: 10%;
	top: 0;
	position: fixed;
}
