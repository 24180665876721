.album {
	position: relative;
	// min-height: 60vh;
	cursor: pointer;

	max-height: 75svh;

	text-transform: capitalize;

	overflow: hidden;

	img {
		min-width: 100%;
		height: 80%;
		transition: 0.5s ease-in-out;
		object-fit: cover;
		object-position: top;

		pointer-events: none;
	}

	.fa-solid {
		font-size: 2.4rem;
		padding-right: 1rem;
		color: $secondary-color;
	}
}

.album:hover img {
	border-radius: 30rem;
	transform: scale(1.1);
	filter: blur(8px);
}

.album:hover .album_text::after {
	width: 100%;
	border-bottom: 3px solid $secondary-color;
}

.album:hover h2 {
	color: $secondary-color;
}

.album:hover p {
	color: $text-color;
}

.album_text {
	pointer-events: none;
	background-color: lighten($page-background, 1%);
	background-color: $page-background;

	height: 20%;
	max-height: 18rem;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 3rem 0 1rem 1rem;

	h2 {
		pointer-events: none;
		font-size: 3.2rem;
		// letter-spacing: 0.2rem;
		transition: 0.75s ease-in-out;
		font-weight: $fw-800;
		position: relative;
		text-transform: uppercase;
	}

	p {
		pointer-events: none;
		align-self: flex-end;
		padding-right: 1rem;
		padding-bottom: 1rem;
		font-weight: $fw-700;
		font-size: 2.2rem;
		text-transform: uppercase;
	}

	&::after {
		pointer-events: none;
		content: '';
		width: 70%;
		position: absolute;
		bottom: -1;
		left: 0;
		border-bottom: 3px solid $text-color;
		transition: 0.5s ease-in-out;
	}
}
