.about-section {
	min-height: 70vh;
	width: 80%;
	margin: 0 auto;

	display: grid;
	place-items: center;

	padding: 3rem;
}

.about-section_text {
	padding: 0 2rem;

	text-align: center;

	h1 {
		font-size: 6rem;
		font-weight: $fw-700;
		letter-spacing: 1.2rem;
		padding-bottom: 2rem;
		color: $secondary-color;
	}

	p {
		font-size: 3.6rem;

		font-weight: $fw-500;
	}
}
