// _PROJECTS SECTION

// .port-projects {
// 	width: 80%;
// 	margin: 0 auto;
// 	min-height: 68vh;

// 	display: grid;
// 	grid-template-columns: repeat(auto-fit, minmax(min(450px, 100%), 1fr));
// 	// place-items: center;
// 	gap: 4rem 2rem;
// }

// _CARDS
// .project-card {
// 	// min-height: 100%;
// 	// margin: auto;
// 	min-height: 68vh;
// 	display: grid;
// 	place-content: center;

// 	cursor: pointer;

// 	// _TRANSFORM
// 	// transform: rotate(5deg) translateY(-15%);

// 	// opacity: 0;

// 	h2 {
// 		font-weight: 800;
// 		font-size: 8rem;
// 		letter-spacing: 1.6rem;
// 		z-index: 1;
// 		padding-top: 2rem;
// 		writing-mode: vertical-lr;

// 		// _TRANSFORM
// 		// transform: translateY(-20%);
// 		// opacity: 0;
// 	}

// 	&:hover {
// 		h2 {
// 			color: $secondary-color;
// 		}
// 	}
// }

.port-projects {
	display: grid;
	place-items: center;
	gap: 6rem;
}
