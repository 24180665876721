// -COLORS
$page-background: rgb(0, 0, 0);

$text-color: #fff;
$secondary-color: #d1c295;
$secondary-color: #faf5e9;

$black-color: black;
$color-test: rgb(100, 100, 100);

// -FONT SIZES
$ff: 'Open Sans', sans-serif;

$logo-fs: 3.6rem;

$nav-links-fs: 2rem;
$nav-links-sc-fs: 4rem;

$hero-fs: 6rem;
$hero-sub-fs: 4.8rem;

$btn-fs-lg: 3.6rem;
$btn-fs-md: 2.4rem;
$btn-fs-sm: 1.8rem;

$section-headings-h3: 5.5rem;
$section-headings-h4: 4.8rem;

$page-headers-h1: 6rem;
$page-headers-h1-sm: 4rem;

// -FONT WEIGHTS

$logo-fw: 800;

$fw-400: 400;
$fw-500: 500;
$fw-700: 700;
$fw-800: 800;

// -DIV WIDTHS
$mobile-width: 95%;

.black-span {
	color: white;
	background-color: black;
	padding: 0 1rem;
}

.white-span {
	color: black;
	background-color: white;
	padding: 0 1rem;
}

.black-span-text {
	color: black;
}

.test-span-text {
	color: $color-test;
}
