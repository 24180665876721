footer {
	min-height: 20vh;

	padding-top: 4rem;
	width: 80%;
	margin: 0 auto;
	color: white;

	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-wrap: wrap;

	li {
		list-style: none;
	}

	h3 {
		font-size: 2rem;
	}

	p {
		font-size: 2.4rem;
	}

	.fa-brands {
		font-size: 3.2rem;
	}

	.copy-right {
		font-weight: 600;
	}

	.fa-solid {
		font-size: 2.4rem;
		padding-right: 1rem;
		color: $secondary-color;
	}
}

.social-links {
	text-align: center;
	ul {
		display: flex;

		li {
			padding: 2rem;
		}
	}

	a:hover {
		color: $secondary-color;
	}
}

.contact-footer {
	// background-color: green;

	ul {
		display: flex;
		flex-direction: column;
	}

	li {
		padding: 1rem;
		cursor: pointer;
		font-size: 1.6rem;
		position: relative;
		font-weight: 600;

		&::after {
			content: '';
			position: absolute;
			bottom: -1;
			left: 0;
			width: 0;
			height: 2px;
			background-color: $secondary-color;
			transition: 0.5s ease-out;
		}

		&:hover::after {
			width: 100%;
		}
	}
}
