.video-card {
	text-align: center;
	width: 90vw;
	max-width: 100rem;

	background-color: $page-background;
}

.video-card_title {
	color: white;

	width: 100%;
	margin: 0 auto;

	// display: flex;
	// flex-direction: column;
	background-color: red;
	// align-items: center;
	// justify-content: flex-end;
	text-align: right;

	h2 {
		font-size: 3rem;
		background-color: black;

		font-weight: $fw-500;
		padding-bottom: 2.4rem;
	}
}

.video-card_title > * {
	flex: 1;
}

.video-container {
	video {
		cursor: pointer;
		// width: 100%;
		// height: 80%;

		object-fit: contain;
	}
}
