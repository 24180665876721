.section-header {
	font-size: $section-headings-h3;
	text-align: center;
	padding-bottom: 3rem;
	overflow: hidden;

	h3 {
		position: relative;
		display: inline-block;
	}

	h3::after {
		content: '';
		position: absolute;
		bottom: -5;
		left: 50%;

		transform: translate(-50%, -50%);
		width: 25%;
		height: 4px;
		background-color: $text-color;
	}
}
