//_ FORM

form {
	//* Flex
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	//* Styles
	padding: 0 4rem;

	width: 100vw;
	max-width: 90rem;

	border-radius: 2rem;

	font-weight: $fw-700;

	//* Position
	position: relative;
	z-index: 10;

	h5 {
		font-size: 4rem;
	}

	label {
		font-size: 2rem;

		display: grid;
		place-items: center;
	}

	input {
		font-size: 2rem;
		padding: 2rem;
		margin-top: 1rem;
		border: none;
		border-bottom: white solid 3px;
		background-color: black;
		outline: none;
		color: white;
	}

	button {
		//* Position
		// position: absolute;
		// bottom: 0;
		color: black;

		//* Styles
		font-size: 3rem;
		font-weight: $fw-800;
		border: none;
		background-color: white;
		width: 100%;

		padding: 2rem 4rem;

		cursor: pointer;

		transition: all 0.5s ease-in-out;

		&:hover {
			background-color: $secondary-color;
			color: #fff;
			color: black;
			border-radius: 2rem;
		}
	}

	textarea {
		padding: 1rem 2rem;
		width: 100%;
		height: 20rem;
		margin-bottom: 4rem;
		border: 1px solid white;
		outline: none;
		color: white;
		background-color: black;
	}
}

label {
	width: 100%;
}

form > * {
	padding: 2rem 0;
}

.form-inputs {
	display: grid;
	grid-template-columns: 1fr 1fr;

	gap: 2rem;

	width: 100%;
	label {
		width: 100%;

		input {
			width: 100%;
		}
	}
}
