// _TABLETS
@media (max-width: 1024px) {
	// -WORK SECTION

	// -FOOTER
	footer {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	footer > * {
		padding: 2rem 0;
	}

	.contact-footer {
		h3 {
			text-align: center;
		}
	}

	// -FASHION
	.album:hover {
		h2 {
			color: $secondary-color;
		}
	}

	.fashion-text {
		width: 80%;
	}

	// -PROJECTS SECTION (PHOTOGRAPHY & VIDEOGRAPHY)

	// .port-projects {
	// 	grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
	// }

	// -EVENTS
	.album-events {
		img {
			height: 85%;
		}
	}

	.album-events_text {
		min-height: 15%;
	}

	// -IMAGES CONTAINER
	.images-container {
		// width: 100%;
		// grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));

		gap: 4rem;

		img:hover {
			transform: scale(1);
		}
	}

	// -ALBUM HEADER
	.album-header {
		grid-template-columns: 1fr;
	}

	// -Homepage
	.form-inputs {
		grid-template-columns: 1fr;
	}
}
