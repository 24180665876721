::-webkit-scrollbar {
	width: 10px;
}
::-webkit-scrollbar-track {
	background: #000;
	margin: 0.5rem;
}
::-webkit-scrollbar-thumb {
	background: #fff;
	border-radius: 100vw;
}
