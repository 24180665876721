.images-container {
	padding: 4rem;
	width: 80%;
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(min(450px, 100%), 1fr));
	place-items: center;
	gap: 2rem;

	img:hover {
		transform: scale(1.4);
	}

	img {
		height: 100%;
		width: 100%;
		object-fit: contain;

		cursor: pointer;

		transition: 0.75s ease-in-out;
	}
}
