// _NARRATIVE ADS

.narrative-ads {
	h1 {
		text-align: center;
		margin-bottom: 5rem;

		font-size: 4.6rem;
		letter-spacing: 1.6rem;
	}
}

// _COMMON
.reels-container {
	width: 80%;
	margin: 0 auto;

	display: grid;
	gap: 4rem;
	grid-template-columns: repeat(auto-fit, minmax(min(400px, 100%), 1fr));

	// *Hide FullScreen Button
	video::-webkit-media-controls-fullscreen-button {
		display: none;
	}
}

.reel {
	min-height: 60vh;

	video {
		// border: 5px solid red;
		cursor: pointer;
		height: 100%;
		max-height: 80vh;
		width: 100%;
		object-fit: contain;
		object-fit: cover;
	}
}
