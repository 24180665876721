.albums {
	width: 80%;
	margin: 0 auto;

	display: grid;

	grid-template-columns: repeat(auto-fit, minmax(min(450px, 100%), 1fr));
	gap: 4rem 2rem;
	padding: 4rem 0;
}
