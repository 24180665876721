@import "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700;800&display=swap";
*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

.black-span {
  color: #fff;
  background-color: #000;
  padding: 0 1rem;
}

.white-span {
  color: #000;
  background-color: #fff;
  padding: 0 1rem;
}

.black-span-text {
  color: #000;
}

.test-span-text {
  color: #646464;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

body {
  color: #fff;
  background-color: #000;
  font-family: Open Sans, sans-serif;
  overflow-x: hidden;
}

a {
  color: #fff;
  text-decoration: none;
}

.span-second {
  color: #faf5e9;
}

a:link, a:visited, a:hover, a:active {
  color: #fff;
  text-decoration: none;
}

.btn {
  cursor: pointer;
  background: none;
  border: none;
  text-decoration: none;
}

.btn-contact {
  font-size: 2.4rem;
  font-weight: 800;
}

.btn-back {
  cursor: pointer;
  color: #fff;
  letter-spacing: .4rem;
  background: none;
  border: none;
  place-items: center;
  padding: 2rem 4rem;
  font-size: 3rem;
  font-weight: 500;
  text-decoration: none;
  transition: all .5s ease-in-out;
  display: flex;
}

.btn-back .fa-chevron-left {
  padding-right: 1rem;
}

.btn-back:hover {
  color: #faf5e9;
}

.btn-top {
  z-index: 100;
  cursor: pointer;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 20rem;
  outline: none;
  padding: 1rem;
  font-size: 4.4rem;
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.btn-projects {
  font-size: 3rem;
}

.work-button {
  margin-top: 3rem;
}

.work-button a {
  transition: all .5s ease-in-out;
}

.work-button a:hover {
  color: #faf5e9;
}

nav {
  z-index: 10000;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  min-height: 10vh;
  margin: 0 auto;
  display: flex;
  position: relative;
}

nav a {
  color: inherit;
  text-decoration: none;
  transition: all .5s ease-in-out;
}

nav a:hover {
  color: #fff;
}

nav p {
  cursor: pointer;
  padding: 0 1rem;
  font-size: 2rem;
  font-weight: 500;
  position: relative;
}

nav p:hover {
  color: #faf5e9;
}

nav ul {
  letter-spacing: .75px;
  justify-content: space-evenly;
  display: flex;
}

nav li {
  list-style: none;
}

nav li a {
  padding: 0 1rem;
  font-size: 2rem;
  font-weight: 500;
  position: relative;
}

nav li a:after {
  content: "";
  transform-origin: 100%;
  background-color: #faf5e9;
  width: 100%;
  height: 2px;
  transition: transform .75s;
  position: absolute;
  bottom: -1rem;
  left: 0;
  transform: scaleX(0);
}

nav li a:hover:after {
  transform-origin: 0;
  transform: scaleX(1);
}

nav > * {
  flex: 1;
}

.logo {
  position: relative;
}

.logo a {
  font-size: 3.6rem;
  font-weight: 800;
}

.logo a:hover {
  color: #faf5e9;
}

.logo2 {
  font-size: 2.2rem;
  font-weight: 700;
  display: block;
  position: absolute;
  left: 0;
  transform: translate(-25%, -20%);
}

.fixed {
  position: fixed;
  top: 0;
  left: 10%;
  right: 10%;
}

.burger {
  cursor: pointer;
  display: none;
}

.burger div {
  background-color: #fff;
  height: 3px;
  margin: 5px;
  transition: all .3s;
}

.line-1 {
  width: 25px;
}

.line-2 {
  width: 18px;
}

.line-3 {
  width: 12px;
}

.toggle .line-1 {
  transform: rotate(-45deg)translate(-5px, 1px);
}

.toggle .line-2 {
  width: 25px;
  transform: rotate(45deg)translate(-5px);
}

.toggle .line-3 {
  opacity: 0;
}

.projects-menu {
  position: relative;
}

.projects-dropdown {
  z-index: 1000;
  background-color: #141414;
  flex-direction: column;
  padding: 2.5rem;
  display: none;
  position: absolute;
  top: calc(100% + .5rem);
}

.projects-dropdown > li {
  padding-bottom: .5rem;
}

.projects-dropdown li a {
  width: 100%;
  padding: 1rem;
  display: block;
}

.projects-dropdown li a:after {
  bottom: 0;
}

.projects-dropdown li a:hover {
  color: #faf5e9;
}

.projects-menu-active {
  display: block;
}

@media (width <= 1024px) {
  nav {
    justify-content: space-between;
    width: 100%;
    padding: 0 4rem;
    left: 0;
    right: 0;
  }

  nav a:hover {
    color: #faf5e9;
  }

  nav > * {
    flex: 0;
  }

  .burger {
    display: block;
  }

  .nav-links {
    color: #fff;
    opacity: 0;
    z-index: 1000;
    background-color: #0d0d0d;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 90dvh;
    font-weight: 700;
    transition: all .75s ease-in;
    display: flex;
    position: fixed;
    top: 10vh;
    left: 0;
    overflow-y: auto;
    transform: translateX(-100%);
  }

  .nav-links a, .nav-links p {
    font-size: 4rem;
  }

  .nav-links li {
    padding-bottom: 3rem;
    padding-left: 1rem;
  }

  .nav-active {
    opacity: 1;
    transform: translateX(0);
  }

  .logo a {
    font-weight: 500;
  }

  .logo2 {
    display: none;
  }

  .projects-dropdown {
    position: relative;
    top: 0;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #000;
  margin: .5rem;
}

::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 100vw;
}

.projects-header {
  place-content: center;
  min-height: 20vh;
  display: grid;
}

.projects-header h1 {
  letter-spacing: 2.8rem;
  font-size: 8rem;
  font-weight: 800;
}

.port-projects {
  place-items: center;
  gap: 6rem;
  display: grid;
}

.card {
  color: #000;
  background-color: #fff;
  grid-template-columns: 1fr 1fr;
  width: 70vw;
  display: grid;
}

.card img {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100%;
}

.card .text {
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  padding: 6rem;
  display: flex;
}

.card h1 {
  letter-spacing: .4rem;
  text-transform: uppercase;
  padding-bottom: 2rem;
  font-size: 6rem;
  font-weight: 800;
}

.card p {
  padding-bottom: 6rem;
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 1.8;
}

.card a {
  text-align: center;
  background-color: #111;
  width: 45%;
  padding: 2rem 4rem;
  font-size: 2.6rem;
}

.card-left img {
  object-position: right;
}

.image {
  position: relative;
  overflow: hidden;
}

.image:before {
  content: "";
  background-color: #fff;
  width: 45%;
  height: 140%;
  position: absolute;
  top: 50%;
  left: -25%;
  transform: translateY(-50%)rotate(20deg);
}

.card-left .image:before {
  content: "";
  left: initial;
  background-color: #fff;
  width: 40%;
  height: 140%;
  position: absolute;
  top: 50%;
  right: -25%;
  transform: translateY(-50%)rotate(-20deg);
}

@media (width <= 1750px) {
  .card {
    grid-template-columns: 1fr;
    width: 90vw;
  }

  .card .image:before {
    display: none;
  }

  .card img {
    object-position: center;
  }

  .card a {
    width: 100%;
    max-width: 40rem;
    margin-top: 4rem;
  }

  .card h1 {
    letter-spacing: .2rem;
    font-size: 5rem;
  }

  .text {
    align-items: center;
  }

  .port-projects {
    gap: 6rem;
  }
}

.albums {
  grid-template-columns: repeat(auto-fit, minmax(min(450px, 100%), 1fr));
  gap: 4rem 2rem;
  width: 80%;
  margin: 0 auto;
  padding: 4rem 0;
  display: grid;
}

.album {
  cursor: pointer;
  text-transform: capitalize;
  max-height: 75svh;
  position: relative;
  overflow: hidden;
}

.album img {
  object-fit: cover;
  object-position: top;
  pointer-events: none;
  min-width: 100%;
  height: 80%;
  transition: all .5s ease-in-out;
}

.album .fa-solid {
  color: #faf5e9;
  padding-right: 1rem;
  font-size: 2.4rem;
}

.album:hover img {
  filter: blur(8px);
  border-radius: 30rem;
  transform: scale(1.1);
}

.album:hover .album_text:after {
  border-bottom: 3px solid #faf5e9;
  width: 100%;
}

.album:hover h2 {
  color: #faf5e9;
}

.album:hover p {
  color: #fff;
}

.album_text {
  pointer-events: none;
  background-color: #000;
  flex-direction: column;
  justify-content: space-between;
  height: 20%;
  max-height: 18rem;
  padding: 3rem 0 1rem 1rem;
  display: flex;
  position: relative;
}

.album_text h2 {
  pointer-events: none;
  text-transform: uppercase;
  font-size: 3.2rem;
  font-weight: 800;
  transition: all .75s ease-in-out;
  position: relative;
}

.album_text p {
  pointer-events: none;
  text-transform: uppercase;
  align-self: flex-end;
  padding-bottom: 1rem;
  padding-right: 1rem;
  font-size: 2.2rem;
  font-weight: 700;
}

.album_text:after {
  pointer-events: none;
  content: "";
  border-bottom: 3px solid #fff;
  width: 70%;
  transition: all .5s ease-in-out;
  position: absolute;
  bottom: -1px;
  left: 0;
}

.album-header {
  grid-template-columns: repeat(auto-fit, minmax(min(450px, 100%), 1fr));
  place-items: center;
  gap: 4rem 0;
  width: 80%;
  margin: auto;
  display: grid;
}

.album-header_text {
  text-align: right;
  text-align: center;
  flex-direction: column;
  width: 100%;
  padding: 2rem 0;
  display: flex;
}

.album-header_text h1 {
  color: #faf5e9;
  letter-spacing: .8rem;
  text-transform: uppercase;
  padding-bottom: 3rem;
  font-size: 5rem;
  font-weight: 800;
}

.album-header_text p {
  padding-bottom: 2rem;
  font-size: 2.8rem;
  font-weight: 500;
}

.album-header_text h4 {
  text-transform: uppercase;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
}

.album-header_text h4 span {
  color: #faf5e9;
  font-size: 2rem;
}

.album-header_text .album-header_title, .album-header_text .album-header_description {
  overflow: hidden;
}

.images-container {
  grid-template-columns: repeat(auto-fit, minmax(min(450px, 100%), 1fr));
  place-items: center;
  gap: 2rem;
  width: 80%;
  margin: 0 auto;
  padding: 4rem;
  display: grid;
}

.images-container img:hover {
  transform: scale(1.4);
}

.images-container img {
  object-fit: contain;
  cursor: pointer;
  width: 100%;
  height: 100%;
  transition: all .75s ease-in-out;
}

.videos-container {
  grid-template-columns: 1fr;
  place-items: center;
  gap: 8rem 0;
  width: 100%;
  margin: 0 auto 4rem;
  padding: 2rem;
  display: grid;
}

.video-card {
  text-align: center;
  background-color: #000;
  width: 90vw;
  max-width: 100rem;
}

.video-card_title {
  color: #fff;
  text-align: right;
  background-color: red;
  width: 100%;
  margin: 0 auto;
}

.video-card_title h2 {
  background-color: #000;
  padding-bottom: 2.4rem;
  font-size: 3rem;
  font-weight: 500;
}

.video-card_title > * {
  flex: 1;
}

.video-container video {
  cursor: pointer;
  object-fit: contain;
}

.narrative-ads h1 {
  text-align: center;
  letter-spacing: 1.6rem;
  margin-bottom: 5rem;
  font-size: 4.6rem;
}

.reels-container {
  grid-template-columns: repeat(auto-fit, minmax(min(400px, 100%), 1fr));
  gap: 4rem;
  width: 80%;
  margin: 0 auto;
  display: grid;
}

.reels-container video::-webkit-media-controls-fullscreen-button {
  display: none;
}

.reel {
  min-height: 60vh;
}

.reel video {
  cursor: pointer;
  object-fit: contain;
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 80vh;
}

.section-header {
  text-align: center;
  padding-bottom: 3rem;
  font-size: 5.5rem;
  overflow: hidden;
}

.section-header h3 {
  display: inline-block;
  position: relative;
}

.section-header h3:after {
  content: "";
  background-color: #fff;
  width: 25%;
  height: 4px;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translate(-50%, -50%);
}

form {
  z-index: 10;
  border-radius: 2rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 90rem;
  padding: 0 4rem;
  font-weight: 700;
  display: flex;
  position: relative;
}

form h5 {
  font-size: 4rem;
}

form label {
  place-items: center;
  font-size: 2rem;
  display: grid;
}

form input {
  color: #fff;
  background-color: #000;
  border: none;
  border-bottom: 3px solid #fff;
  outline: none;
  margin-top: 1rem;
  padding: 2rem;
  font-size: 2rem;
}

form button {
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border: none;
  width: 100%;
  padding: 2rem 4rem;
  font-size: 3rem;
  font-weight: 800;
  transition: all .5s ease-in-out;
}

form button:hover {
  color: #000;
  background-color: #faf5e9;
  border-radius: 2rem;
}

form textarea {
  color: #fff;
  background-color: #000;
  border: 1px solid #fff;
  outline: none;
  width: 100%;
  height: 20rem;
  margin-bottom: 4rem;
  padding: 1rem 2rem;
}

label {
  width: 100%;
}

form > * {
  padding: 2rem 0;
}

.form-inputs {
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  width: 100%;
  display: grid;
}

.form-inputs label, .form-inputs label input {
  width: 100%;
}

section.hero {
  justify-content: center;
  align-items: center;
  width: 80%;
  min-height: 80svh;
  margin: 0 auto;
  display: flex;
}

section.hero h1 {
  padding-bottom: 4.6rem;
  font-size: 8rem;
}

section.hero a {
  color: #000;
  background-color: #fff;
  padding: 2rem 4rem;
  font-size: 3rem;
  font-weight: 700;
  transition: all .5s ease-in-out;
}

section.hero a:hover {
  color: #000;
  background-color: #faf5e9;
  border-radius: 2rem;
}

section.hero .layer-hero-title {
  width: 100%;
  margin: 0 auto;
}

section.hero .auto-type {
  color: #faf5e9;
}

.layer {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  min-height: 100svh;
  padding-top: 2rem;
  padding-bottom: 8rem;
  display: flex;
  position: sticky;
  top: 0;
}

.layer:first-child, .layer:nth-child(7) {
  background: #000;
}

.layer:nth-child(2) {
  background-color: #0000;
  background-image: linear-gradient(to right, #0200244d 0%, #00000080 100%), url("img1.a0b1f4e6.webp");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.layer:nth-child(3) {
  background-color: #0000;
  background-image: linear-gradient(to right, #02002433 0%, #0006 100%), url("img2.37b61128.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.layer:nth-child(4) {
  background-color: #0000;
  background-image: linear-gradient(90deg, #0200244d 0%, #0009 100%), url("img3.08fe56de.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.layer:nth-child(5) {
  background-color: #0000;
  background-image: linear-gradient(#02002433 0%, #0006 100%), url("img4.9a1b1761.webp");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.layer:nth-child(6) {
  background-color: #000;
}

.layer h1 {
  text-transform: uppercase;
  font-size: 5.8rem;
  font-weight: 800;
}

.layer p {
  padding-bottom: 4rem;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 1.8;
}

.layer a {
  background-color: #000;
  padding: 2rem 4rem;
  font-size: 3rem;
  font-weight: 700;
  transition: all .5s ease-in-out;
}

.layer a:hover {
  color: #000;
  background-color: #fff;
  border-radius: 2rem;
}

.layer-main-container {
  width: 80%;
  margin: 0 auto;
}

.layer-text {
  width: 75%;
}

@media (width <= 700px) {
  .layer-main-container {
    width: 100%;
    padding-top: 6rem;
    padding-bottom: 12rem;
  }

  .layer-text {
    width: 100%;
  }

  .layer {
    width: 100%;
    padding-top: 6rem;
    padding-bottom: 25rem;
  }

  .layer p {
    font-size: 2.4rem;
  }

  .layer h1 {
    font-size: 4rem;
  }

  .layer a {
    font-size: 2.6rem;
  }
}

@media (width <= 1024px) {
  .layer {
    padding-top: 6rem;
    padding-bottom: 25rem;
  }

  .layer:nth-child(2), .layer:nth-child(3), .layer:nth-child(4), .layer:nth-child(5) {
    background-attachment: scroll;
  }

  .layer-main-container {
    width: 100%;
    padding-left: 2rem;
  }
}

.contact-layer {
  min-height: 90vh;
  padding-bottom: 0;
}

.contact-layer .layer-title {
  text-align: center;
  width: 100%;
  font-size: 4.8rem;
}

.clients-wrapper {
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  place-items: center;
  width: 90vw;
  max-width: 220rem;
  margin: 0 auto;
  padding-bottom: 4rem;
  display: grid;
}

.clients-wrapper :nth-child(4) {
  width: 75%;
}

.clients-wrapper img {
  object-fit: contain;
  width: 80%;
  height: 100%;
}

.clients-section {
  text-align: center;
  width: 100%;
  margin: 0 auto 2rem;
}

.clients-section h1 {
  letter-spacing: .4rem;
  padding-top: 2rem;
}

@media (width <= 700px) {
  .clients-wrapper img {
    width: 70%;
  }

  .clients-wrapper :nth-child(6) {
    width: 75%;
  }
}

.about-section {
  place-items: center;
  width: 80%;
  min-height: 70vh;
  margin: 0 auto;
  padding: 3rem;
  display: grid;
}

.about-section_text {
  text-align: center;
  padding: 0 2rem;
}

.about-section_text h1 {
  letter-spacing: 1.2rem;
  color: #faf5e9;
  padding-bottom: 2rem;
  font-size: 6rem;
  font-weight: 700;
}

.about-section_text p {
  font-size: 3.6rem;
  font-weight: 500;
}

.contact-section {
  place-items: center;
  min-height: 70vh;
  display: grid;
}

.contact-section .layer-title {
  text-align: center;
  width: 100%;
  font-size: 4.8rem;
}

footer {
  color: #fff;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  min-height: 20vh;
  margin: 0 auto;
  padding-top: 4rem;
  display: flex;
}

footer li {
  list-style: none;
}

footer h3 {
  font-size: 2rem;
}

footer p {
  font-size: 2.4rem;
}

footer .fa-brands {
  font-size: 3.2rem;
}

footer .copy-right {
  font-weight: 600;
}

footer .fa-solid {
  color: #faf5e9;
  padding-right: 1rem;
  font-size: 2.4rem;
}

.social-links {
  text-align: center;
}

.social-links ul {
  display: flex;
}

.social-links ul li {
  padding: 2rem;
}

.social-links a:hover {
  color: #faf5e9;
}

.contact-footer ul {
  flex-direction: column;
  display: flex;
}

.contact-footer li {
  cursor: pointer;
  padding: 1rem;
  font-size: 1.6rem;
  font-weight: 600;
  position: relative;
}

.contact-footer li:after {
  content: "";
  background-color: #faf5e9;
  width: 0;
  height: 2px;
  transition: all .5s ease-out;
  position: absolute;
  bottom: -1px;
  left: 0;
}

.contact-footer li:hover:after {
  width: 100%;
}

@media (width >= 3840px) {
  html {
    font-size: 70%;
  }

  .project-card h2 {
    letter-spacing: 5rem;
  }

  .albums {
    grid-template-columns: repeat(auto-fit, minmax(min(650px, 100%), 1fr));
  }
}

@media (width >= 1024px) {
  .album h2:before {
    -webkit-text-fill-color: transparent;
    white-space: nowrap;
    content: attr(data-text);
    background: #faf5e9 text;
    height: 0;
    transition: all 1s ease-out;
    position: absolute;
    left: 0;
    overflow: hidden;
  }
}

@media (width <= 1730px) {
  .port-projects {
    grid-template-columns: 1fr;
  }
}

@media (width >= 1024px) {
  .cards-wrapper {
    grid-template-rows: 2fr;
    grid-template-columns: 1fr 1fr;
    display: grid;
  }

  .events {
    grid-column: 1 / 3;
  }
}

@media (width <= 1920px) {
  html {
    font-size: 50%;
  }
}

@media (width <= 1280px) {
  html {
    font-size: 50%;
  }

  .project-card {
    min-height: 65vh;
  }

  .card-header h4 {
    letter-spacing: 1.2rem;
  }
}

@media (width <= 1024px) {
  footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  footer > * {
    padding: 2rem 0;
  }

  .contact-footer h3 {
    text-align: center;
  }

  .album:hover h2 {
    color: #faf5e9;
  }

  .fashion-text {
    width: 80%;
  }

  .album-events img {
    height: 85%;
  }

  .album-events_text {
    min-height: 15%;
  }

  .images-container {
    gap: 4rem;
  }

  .images-container img:hover {
    transform: scale(1);
  }

  .album-header, .form-inputs {
    grid-template-columns: 1fr;
  }
}

@media (width <= 700px) {
  html {
    font-size: 40%;
  }

  .projects-header h1 {
    letter-spacing: 1.4rem;
    font-size: 4rem;
  }

  .card-header h4 {
    letter-spacing: 1px;
  }

  .album {
    margin-bottom: 2rem;
  }

  .album_text {
    padding: 2rem;
  }

  .album_text h2 {
    font-size: 2.6rem;
  }

  .album_text p {
    font-size: 2.2rem;
  }

  .album-header_text h1 {
    width: 100%;
  }

  .events-text {
    width: 90%;
  }

  .about-section {
    width: 95%;
  }

  .about-section_text h1 {
    letter-spacing: 1.6rem;
    padding-bottom: 3rem;
    font-size: 3.6rem;
    font-weight: 800;
  }

  .about-section_text p {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 2.2;
  }

  .images-container img:hover {
    transform: scale(1);
  }

  .reels-container {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .album-header_text h1 {
    font-size: 4rem;
  }

  .video-card_title h2 {
    font-size: 2.4rem;
  }

  .video-card_title p {
    letter-spacing: .6rem;
    font-size: 1.6rem;
  }

  .videos-container {
    width: 95%;
  }

  .layer-title {
    font-size: 4rem !important;
  }
}
/*# sourceMappingURL=index.e2a87c7f.css.map */
