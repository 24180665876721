.contact-section {
	min-height: 70vh;

	display: grid;
	place-items: center;

	.layer-title {
		text-align: center;
		width: 100%;

		font-size: 4.8rem;
	}
}
