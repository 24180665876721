.burger {
	display: none;
	cursor: pointer;
}

.burger div {
	height: 3px;
	background-color: $text-color;
	margin: 5px;
	transition: 0.3s ease;
}

.line-1 {
	width: 25px;
}

.line-2 {
	width: 18px;
}

.line-3 {
	width: 12px;
}

// -CHANGE CLOSE ICONE TO X

.toggle .line-1 {
	transform: rotate(-45deg) translate(-5px, 1px);
}

.toggle .line-2 {
	width: 25px;
	transform: rotate(45deg) translate(-5px, 0px);
}

.toggle .line-3 {
	opacity: 0;
}


