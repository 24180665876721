.album-header {
	// min-height: 15vh;
	width: 80%;
	margin: auto;
	// background-color: red;

	display: grid;
	place-items: center;
	grid-template-columns: repeat(auto-fit, minmax(min(450px, 100%), 1fr));
	// padding: 2rem 0;
	gap: 4rem 0;
}

.album-header_text {
	text-align: right;
	text-align: center;
	// background-color: darkgray;
	width: 100%;
	display: flex;
	flex-direction: column;

	padding: 2rem 0;

	h1 {
		font-size: 5rem;
		font-weight: 800;
		color: $secondary-color;
		letter-spacing: 0.8rem;
		padding-bottom: 3rem;
		text-transform: uppercase;
	}

	p {
		font-size: 2.8rem;
		padding-bottom: 2rem;
		font-weight: 500;
	}

	h4 {
		text-transform: uppercase;
		font-size: 1.6rem;
		color: $text-color;
		font-weight: 600;

		span {
			font-size: 2rem;
			color: $secondary-color;
		}
	}

	.album-header_title,
	.album-header_description {
		overflow: hidden;
		// color: white;
	}
}
