.card {
	width: 70vw;
	// max-width: 165rem;
	// max-height: 40vh;
	// min-height: 40vh;

	background-color: white;

	color: black;

	display: grid;
	grid-template-columns: 1fr 1fr;

	// border: 10px solid red;

	// *NEW TEST CODE
	// height: 30vh;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: top;
	}

	.text {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;

		padding: 6rem;
	}

	h1 {
		font-size: 6rem;
		letter-spacing: 0.4rem;
		padding-bottom: 2rem;
		text-transform: uppercase;
		font-weight: $fw-800;
	}

	p {
		font-size: 2.6rem;
		font-weight: $fw-700;
		padding-bottom: 6rem;
		line-height: 1.8;
	}

	a {
		background-color: #111;
		width: 45%;
		padding: 2rem 4rem;
		text-align: center;
		font-size: 2.6rem;
	}
}

.card-left {
	img {
		object-position: right;
	}
}

.image {
	position: relative;
	overflow: hidden;
}

.image::before {
	content: '';
	position: absolute;
	left: -25%;
	top: 50%;
	transform: translateY(-50%) rotate(20deg);
	background-color: white;
	width: 45%;
	height: 140%;
}

.card-left .image::before {
	content: '';
	position: absolute;
	left: initial;
	right: -25%;
	top: 50%;
	transform: translateY(-50%) rotate(-20deg);
	background-color: white;
	width: 40%;
	height: 140%;
}

@media (max-width: 1750px) {
	.card {
		grid-template-columns: 1fr;
		width: 90vw;
		// min-height: 90rem;
		// height: 60vh;

		.image::before {
			display: none;
		}

		img {
			object-position: center;
		}

		a {
			width: 100%;
			max-width: 40rem;
			margin-top: 4rem;
		}

		h1 {
			font-size: 5rem;
			letter-spacing: 0.2rem;
		}
	}

	.text {
		// justify-content: center;
		align-items: center;
	}

	.port-projects {
		gap: 6rem;
	}
}
