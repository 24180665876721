section.hero {
	// background-color: red;
	width: 80%;
	margin: 0 auto;

	min-height: 80svh;

	display: flex;
	justify-content: center;
	align-items: center;

	h1 {
		font-size: 8rem;
		padding-bottom: 4.6rem;
	}

	a {
		font-size: 3rem;
		background-color: #fff;
		padding: 2rem 4rem;
		transition: all 0.5s ease-in-out;
		font-weight: $fw-700;
		color: black;

		&:hover {
			background-color: $secondary-color;
			color: #fff;
			color: black;
			border-radius: 2rem;
		}
	}

	.layer-hero-title {
		width: 100%;
		margin: 0 auto;
	}

	.auto-type {
		color: $secondary-color;
	}
}
