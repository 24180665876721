// _DROP DOWN

.projects-menu {
	position: relative;
}

.projects-dropdown {
	background-color: lighten($page-background, 8%);
	padding: 2.5rem;

	display: flex;
	flex-direction: column;

	position: absolute;
	z-index: 1000;
	top: calc(100% + 0.5rem);

	display: none;
}

.projects-dropdown > li {
	padding-bottom: 0.5rem;
}

.projects-dropdown li a {
	display: block;
	width: 100%;
	padding: 1rem;

	&::after {
		bottom: 0rem;
	}
}

.projects-dropdown li a:hover {
	color: $secondary-color;
}

.projects-menu-active {
	display: block;
}
