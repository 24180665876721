.btn {
	cursor: pointer;
	background: none;
	border: none;
	text-decoration: none;
}

.btn-contact {
	font-size: 2.4rem;
	font-weight: 800;
}

.btn-back {
	cursor: pointer;
	border: none;
	background: none;
	text-decoration: none;
	color: $text-color;
	font-size: 3rem;
	font-weight: $fw-500;
	letter-spacing: 0.4rem;

	padding: 2rem 4rem;

	display: flex;
	place-items: center;

	transition: 0.5s ease-in-out;

	.fa-chevron-left {
		padding-right: 1rem;
	}
}

.btn-back:hover {
	color: $secondary-color;
}

.btn-top {
	display: none;
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 100;
	border: none;
	outline: none;
	background-color: white;

	cursor: pointer;
	padding: 1rem;
	border-radius: 20rem;

	font-size: 4.4rem;
	color: black;
}

.btn-projects {
	font-size: 3rem;
}

.work-button {
	margin-top: 3rem;

	a {
		transition: 0.5s ease-in-out;
	}

	a:hover {
		color: $secondary-color;
	}
}
