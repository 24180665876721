.layer {
	min-height: 100svh;
	max-width: 100%;
	padding-top: 2rem;
	padding-bottom: 8rem;

	position: sticky;
	top: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;

	&:nth-child(1) {
		background: $page-background;
	}
	&:nth-child(7) {
		background: $page-background;
	}

	// _IMAGES

	// _VIDEO PRODUCTION
	&:nth-child(2) {
		background: linear-gradient(
				to right,
				rgba(2, 0, 36, 0.3) 0%,
				rgba(0, 0, 0, 0.5) 100%
			),
			url('/src/assets/img1.webp');

		background-position: top;
		background-size: cover;
		background-repeat: no-repeat;
		background-attachment: fixed;
	}

	// _PHOTO PRODUCTION
	&:nth-child(3) {
		background: linear-gradient(
				to right,
				rgba(2, 0, 36, 0.2) 0%,
				rgba(0, 0, 0, 0.4) 100%
			),
			url('/src/assets/img2.jpg');

		background-position: top;
		background-size: cover;
		background-repeat: no-repeat;
		background-attachment: fixed;
	}

	// _EVENT COVERAGE
	&:nth-child(4) {
		background: linear-gradient(
				90deg,
				rgba(2, 0, 36, 0.3) 0%,
				rgba(0, 0, 0, 0.6) 100%
			),
			url('/src/assets/img3.webp');

		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		background-attachment: fixed;
	}

	// _SOCIAL MEDIA COVEREAGE
	&:nth-child(5) {
		background: linear-gradient(rgba(2, 0, 36, 0.2) 0%, rgba(0, 0, 0, 0.4) 100%),
			url('/src/assets/img4.webp');

		background-position: top;
		background-size: cover;
		background-repeat: no-repeat;
		background-attachment: fixed;
	}

	// _CONTACT
	&:nth-child(6) {
		background-color: $page-background;
	}

	h1 {
		font-size: 5.8rem;
		text-transform: uppercase;
		font-weight: $fw-800;
	}

	p {
		font-size: 3.2rem;
		padding-bottom: 4rem;
		font-weight: 500;
		line-height: 1.8;
	}

	a {
		font-size: 3rem;
		background-color: rgba(0, 0, 0);
		padding: 2rem 4rem;
		transition: all 0.5s ease-in-out;
		font-weight: $fw-700;

		&:hover {
			background-color: white;
			color: $black-color;
			border-radius: 2rem;
		}
	}
}

.layer-main-container {
	width: 80%;
	margin: 0 auto;
}

.layer-text {
	width: 75%;
}

@media (max-width: 700px) {
	.layer-main-container {
		width: 100%;
		padding-top: 6rem;
		padding-bottom: 12rem;
	}

	.layer-text {
		width: 100%;
	}

	.layer {
		width: 100%;
		padding-top: 6rem;
		padding-bottom: 25rem;

		p {
			font-size: 2.4rem;
		}

		h1 {
			font-size: 4rem;
		}

		a {
			font-size: 2.6rem;
		}
	}
}

@media (max-width: 1024px) {
	.layer {
		padding-top: 6rem;
		padding-bottom: 25rem;

		&:nth-child(2) {
			background-attachment: scroll;
		}

		&:nth-child(3) {
			background-attachment: scroll;
		}

		&:nth-child(4) {
			background-attachment: scroll;
		}

		&:nth-child(5) {
			background-attachment: scroll;
		}
	}
}

@media (max-width: 1024px) {
	.layer-main-container {
		width: 100%;
		padding-left: 2rem;
	}
}
