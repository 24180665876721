.videos-container {
	width: 100%;
	margin: 0 auto;

	display: grid;
	grid-template-columns: 1fr;
	place-items: center;
	gap: 8rem 0;
	padding: 2rem;
	margin-bottom: 4rem;

	// *SNAP
	// scroll-snap-type: y mandatory;
	// overflow-y: scroll;
	// height: 100svh;
	// width: 100%;

	// .video-card {
	// 	scroll-snap-align: start;
	// }
}
