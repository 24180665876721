.contact-layer {
	padding-bottom: 0;

	min-height: 90vh;

	.layer-title {
		text-align: center;
		width: 100%;

		font-size: 4.8rem;
	}
}
