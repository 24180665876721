// _PHONES
@media (max-width: 700px) {
	html {
		font-size: 40%;
	}

	// -PROECTS SECTION (PHOTOGRAPHY & VIDEOGRAPHY)
	// _HEADER
	.projects-header {
		h1 {
			font-size: $page-headers-h1-sm;
			letter-spacing: 1.4rem;
		}
	}

	// _CARD HEADER
	.card-header {
		h4 {
			letter-spacing: 1;
		}
	}

	// -FASHION PAGE

	.album {
		margin-bottom: 2rem;
	}

	.album_text {
		padding: 2rem;
		h2 {
			font-size: 2.6rem;
		}

		p {
			font-size: 2.2rem;
		}
	}

	.album-header_text {
		h1 {
			width: 100%;
		}
	}

	// -EVENTS PAGE
	.events-text {
		width: 90%;
	}

	// -ABOUT PAGE

	.about-section {
		width: 95%;
	}

	.about-section_text {
		h1 {
			font-size: 3.6rem;
			padding-bottom: 3rem;
			font-weight: 800;
			letter-spacing: 1.6rem;
		}

		p {
			font-size: 2.4rem;
			line-height: 2.2;
			font-weight: 700;
		}
	}

	// -ALBUM IMAGES
	.images-container {
		// width: 100%;
		// grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

		img:hover {
			transform: scale(1);
		}
	}

	// -REELS
	.reels-container {
		grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	}

	// -ALBUMS HEADER
	.album-header_text {
		h1 {
			// letter-spacing: 1.4rem;
			font-size: 4rem;
		}
	}

	// -VIDEOS PAGE
	.video-card_title {
		h2 {
			font-size: 2.4rem;
		}

		p {
			font-size: 1.6rem;
			letter-spacing: 0.6rem;
		}
	}

	// _VIDOES CONTAINER
	.videos-container {
		width: 95%;
	}

	// -HOMEPAGE
	.layer-title {
		font-size: 4rem !important;
	}
}
