@media (max-width: 1024px) {
	nav {
		width: 100%;
		padding: 0 4rem;
		left: 0;
		right: 0;
		justify-content: space-between;

		a {
			&:hover {
				color: $secondary-color;
			}
		}
	}

	nav > * {
		flex: 0;
	}

	.burger {
		display: block;
	}

	.nav-links {
		position: fixed;
		top: 10vh;
		left: 0;

		font-weight: 700;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: lighten($page-background, 5%);
		color: $text-color;
		width: 100vw;
		height: 90dvh;

		transform: translateX(-100%);
		transition: 0.75s ease-in;
		opacity: 0;
		z-index: 1000;

		overflow-y: auto;

		font-weight: $fw-700;
		a {
			font-size: $nav-links-sc-fs;
		}

		p {
			font-size: $nav-links-sc-fs;
		}

		li {
			padding-bottom: 3rem;
			padding-left: 1rem;
		}
	}

	.nav-active {
		transform: translateX(0);
		opacity: 1;
	}

	.logo {
		a {
			font-weight: 500;
		}
	}

	.logo2 {
		display: none;
	}

	.projects-dropdown {
		position: relative;
		top: 0;

		// display: block;
	}
}
